<template>
  <div class="form-view">
    <LEDDLog
      v-if="form && form.displayType === 'ledd_log'"
      :read-only="isReadOnly"
      :form-version-id="currentFormVersionId"
      @removeForm="handleRemoveForm(form.instance)"
    />
    <LogForm
      v-else-if="form && form.displayType === 'log' || form.displayType === 'medical_conditions'"
      :read-only="isReadOnly"
      :form-version-id="currentFormVersionId"
      :visit="visit"
      @removeForm="handleRemoveForm(form.instance)"
    />
    <PatientForm
      v-else-if="form && !$apollo.queries.formInstance.loading"
      ref="PatientForm"
      :read-only="isReadOnly"
      :is-validating="isValidatingPatientForm"
      :form="form"
      :visit-start-date="visit.visitInstance.startDate"
      :visit="visit"
      @removeForm="handleRemoveForm(form.instance)"
    />
    <EmptyState
      v-else-if="!$apollo.queries.formInstance.loading & !form"
      title="Form Not Found"
      image="form"
      :image-above-text="true"
    />

    <Modal
      v-if="markCompleteModalVisible"
      title="Mark Form as Complete"
      @close="markCompleteModalVisible = false, markFormAsComplete = false"
    >
      <template v-slot:content>
        <p class="mark-form-complete-copy">
          Are you sure you want to navigate away from this form? In order to complete
          the visit, all forms must be marked as "Complete."
        </p>
        <BfCheckbox
          v-model="markFormAsComplete"
          class="mark-complete-checkbox"
          label="Mark form as complete"
        />
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="handleMarkCompleteModalReturnClick"
        >
          Return to form
        </el-button>
        <el-button
          type="outline"
          @click="handleMarkCompleteModalContinueClick"
        >
          Continue
        </el-button>
      </template>
    </Modal>

    <Modal
      v-if="unansweredQuestionsModalVisible"
      title="Unanswered Questions Remaining"
      @close="handleReturnToFormClick"
    >
      <template v-slot:content>
        Are you sure you want to navigate away from this form?
        In order to complete the visit, all questions must be answered or marked as “Did Not Complete”.
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="handleReturnToFormClick"
        >
          Return to Form
        </el-button>
        <el-button
          type="outline"
          @click="handleContinueClick"
        >
          Continue
        </el-button>
      </template>
    </Modal>

    <RemoveFormModal
      v-if="formToRemove"
      @close="formToRemove = null"
      @removeForm="removeFormAndNavigate(formToRemove)"
    />
  </div>
</template>

<script>
import RemoveFormModal from '@/components/RemoveFormModal/RemoveFormModal'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'
import PatientForm from '@/components/PatientForm/PatientForm'
import LogForm from '@/components/LogForm/LogForm'
import LEDDLog from '@/components/LEDDLog/LEDDLog'
import Modal from '@/components/Modal/Modal'
import EmptyState from '@/components/EmptyState/EmptyState'
import FORM_INSTANCE_QUERY from '@/graphql/forms/FormInstanceQuery.graphql'
import LOG_FORM_INSTANCE_QUERY from '@/graphql/forms/LogFormInstanceQuery.graphql'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import { orderQuestionsAndOptions } from '@/utils/form'
import { VisitStatus, FormStatus } from '@/utils/constants'
import { logError } from '@/utils/logging'
import completeForm from '@/mixins/completeForm'
import removeForm from '@/mixins/removeForm'
import { mapActions } from 'vuex'
import visitWindows from '../../mixins/queries/visitWindows'

export default {
  components: {
    PatientForm,
    LogForm,
    LEDDLog,
    Modal,
    BfCheckbox,
    EmptyState,
    RemoveFormModal
  },
  mixins: [ completeForm, removeForm, visitWindows ],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formInstance: null,
      logFormInstance: false,
      useLogFormInstance: false,
      markFormAsComplete: false,
      unansweredQuestionsModalVisible: false,
      markCompleteModalVisible: false,
      isValidatingPatientForm: false,
      routerNext: null,
      formToRemove: null,
      skipNavigationGuard: false
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.skipNavigationGuard) {
      this.navigationGuard(next)
    } else {
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.skipNavigationGuard) {
      this.navigationGuard(next)
    } else {
      next()
    }
  },
  apollo: {
    user() {
      return {
        query: GET_USER_QUERY,
        update: data => data.getUser,
        error (error) {
          logError(error, 'FormView.vue user query')
        }
      }
    },
    formInstance() {
      this.$store.dispatch('updateLoading', true)
      return {
        query: FORM_INSTANCE_QUERY,
        // cache and network to get new form from network after excluding a participant
        fetchPolicy: 'cache-and-network',
        variables() {
          return {
            formInstanceId: this.$route.params.formInstanceId
          }
        },
        update: data => {
          if (data.getFormInstance) {
            return orderQuestionsAndOptions(data.getFormInstance)
          }
          // if getFormInstance does not exist, no form instances were found.
          return null
        },
        error (error) {
          // this flag allows us to skip the logFormInstance query until we need to run it.
          this.useLogFormInstance = true
          // only log the error if it is NOT the "not found" error
          if (!error.graphQLErrors[0].message.indexOf('No form instance')) {
            logError(error, 'FormView form instance query')
          }
        },
        result() {
          this.$store.dispatch('updateLoading', false)
        }
      }
    },

    logFormInstance() {
      return {
        query: LOG_FORM_INSTANCE_QUERY,
        variables() {
          return {
            formInstanceId: this.$route.params.formInstanceId
          }
        },
        update: data => {
          // move displayType to the top of the object to match standard form schema displayType lookup.
          return data.getLogFormInstance ? {
            ...data.getLogFormInstance,
            displayType: data.getLogFormInstance.instance.formVersion.displayType || null
          } : null
        },
        error (error) {
          // only log the error if it is NOT the "not found" error
          if (!error.graphQLErrors[0].message.indexOf('Cannot return a log form instance')) {
            logError(error, 'FormView.vue form instance query')
          }
        },
        skip() {
          return !this.useLogFormInstance
        }
      }
    },

    visit() {
      return {
        query: GET_VISIT_QUERY,
        fetchPolicy: 'cache-first',
        variables() {
          return {
            visitInstanceId: this.$route.params.visitInstanceId
          }
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'FormView.vue visit query')
        }
      }
    }
  },
  computed: {
    // form falls back to logFormInstance if no formInstance is found.
    form() {
      if (this.formInstance) {
        return this.formInstance
      } else if (this.logFormInstance) {
        return this.logFormInstance
      }
      return {}
    },
    currentFormVersionId() {
      return this.form.instance.formVersion ? this.form.instance.formVersion.id : ''
    },
    isReadOnly() {
      const inProgress = this.getInProgressVisitWindow[0]?.visits
        .some(visit => visit.visitInstance.status === VisitStatus.IN_PROGRESS)

      const lastVisitIsCompleted = this.completedVisitWindows[this.completedVisitWindows.length - 1]?.visits
        .some(visit => visit.visitInstance.status === VisitStatus.REOPENED)

      return this.readOnly ||
        this.visitStatus === VisitStatus.COMPLETE ||
        this.form.status === FormStatus.NOT_COMPLETING ||
        !!(inProgress && lastVisitIsCompleted)
    },
    visitStatus() {
      return this.visit && this.visit.visitInstance.status
    }
  },
  methods: {
    ...mapActions(['addToastMessage']),
    handleRemoveForm(formInstance) {
      // if form hasn't been started just removed it, otherwise show warning modal
      if (formInstance.status === FormStatus.NOT_STARTED) {
        this.removeFormAndNavigate(formInstance)
      } else if (this.formToRemove) {
        this.removeFormAndNavigate(this.formToRemove)
      } else {
        // displays the confirmation modal
        this.formToRemove = formInstance
      }
    },
    removeFormAndNavigate(formInstance) {
      this.removeForm(formInstance)
        .then(() => {
          this.skipNavigationGuard = true

          this.$router.push({
            name: 'visitManagement',
            params: {
              ...this.$route.params
            }
          })

          this.addToastMessage({
            type: 'success',
            text: `Removed "${formInstance.formVersion.title}" from the visit`
          })
        })
    },
    handleMarkCompleteModalContinueClick() {
      this.markCompleteModalVisible = false

      if (this.markFormAsComplete) {
        if (this.form.displayType === 'screen_fail') {
          /**
           * if its the screen fail form, defer to PatientForm's complete form function,
           * which has special functionality for screen fail forms
           */
          this.$refs.PatientForm.handleCompleteForm()
        } else {
          this.completeForm(this.form.instance.id)
            .then(() => this.routerNext())
        }
      } else {
        this.routerNext()
      }

      this.markFormAsComplete = false
    },
    handleMarkCompleteModalReturnClick() {
      this.markCompleteModalVisible = false

      if (this.markFormAsComplete) {
        this.completeForm(this.form.instance.id)
      }

      this.markFormAsComplete = false
    },
    handleContinueClick() {
      this.unansweredQuestionsModalVisible = false
      this.isValidatingPatientForm = false

      this.routerNext()
    },
    handleReturnToFormClick() {
      this.isValidatingPatientForm = true
      this.unansweredQuestionsModalVisible = false
    },
    /**
     * If there are any incomplete questions on the form, prevent the user from leaving and instead show the
     * "are you sure" modal.
     */
    navigationGuard(next) {
      const _PatientForm = this.$refs.PatientForm
      this.isValidatingPatientForm = false

      if (_PatientForm &&
        this.form.instance.status === FormStatus.IN_PROGRESS &&
        !this.isReadOnly &&
        // work around for navigating away from form after marking participant as excluded
        _PatientForm.screenFailModalVisible === false
      ) {
        this.routerNext = next

        // if form can be marked as complete, warn them of that, otherwise warn of unanswered questions
        if (_PatientForm.allQuestionsCompleted && _PatientForm.validationErrors.length === 0) {
          this.markCompleteModalVisible = true
        } else {
          this.unansweredQuestionsModalVisible = true
        }
      } else {
        next()
      }
    }
  }
}
</script>
<style lang="scss">
.form-view {
  // Reset padding. We handle the outer padding within our form components for better placement of scrollbars
  margin: -1.5rem;
  height: calc(100% + 3rem);
}

.mark-complete-checkbox .bf-checkbox {
  margin-bottom: 0px;
  margin-top: 2rem;
}

.mark-form-complete-copy {
  margin: 0;
}
</style>
